



























































































































































































































































import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { usePlan, getIntervalText } from '@/admin/plan';
import { SUBSCRIPTION_PLAN_STATUS } from '@/admin/payment';
import { getOptionsTitle } from '@/admin/util';
import { useNotification } from '@/composition/notification';
import { useConfirm } from '@/composition/confirm';
import FcDate from '@/components/FcDate.vue';
import FcNumberForm from '@/components/FcNumberForm.vue';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';

export default defineComponent({
  name: 'Plan',
  components: {
    FcDate,
    FcNumberForm,
    FcRoleLoading,
    FcRoleDeny,
  },
  props: {
    planId: {
      type: String,
      require: false,
      default: '',
    },
  },
  setup({ planId }, context) {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('plans'));
    const isShippingAddress: boolean = process.env.VUE_APP_IS_SHIPPING === 'true';

    const { plan } = usePlan(planId);
    const notification = useNotification();
    const { confirmDialog } = useConfirm();
    const minValue = 0;
    const isUpdatable = computed(
      () =>
        plan.item.subscriptionPlanStatus !== SUBSCRIPTION_PLAN_STATUS.DURING &&
        plan.item.subscriptionPlanStatus !== SUBSCRIPTION_PLAN_STATUS.ACTIVE &&
        plan.isNoSubscriptions
    );
    const isEditInterval = ref(false);
    const isEditPrice = ref(false);
    const isEditInitialPrice = ref(false);
    const priceErrorMessage = ref('');
    const initialPriceErrorMessage = ref('');

    const isMaxSubscribersCountEnabled = ref(false); // 加入者数上限を設定するかどうか

    // 料金・初回料金に入力された値が0以下の場合エラーを表示
    watch(
      () => plan.item.price,
      () => {
        priceErrorMessage.value = '';
        if (
          typeof plan.item.price !== 'number' ||
          (typeof plan.item.price === 'number' && plan.item.price < minValue)
        ) {
          priceErrorMessage.value = '設定できる範囲外です';
        }
      }
    );
    watch(
      () => plan.item.initialPrice,
      () => {
        initialPriceErrorMessage.value = '';
        if (typeof plan.item.initialPrice === 'number' && plan.item.initialPrice < minValue) {
          initialPriceErrorMessage.value = '設定できる範囲外です';
        }
      }
    );
    const isFirst = ref(true); // watchで初回のみ処理を行うためのフラグ
    watch(
      () => plan.item.maxSubscribersCount,
      () => {
        if (isFirst.value) {
          // NOTE: 変更のたびに設定させたくないので、初回のみ設定させる（watchで監視しないと、usePlan(planId)後の初期値の反映が行えないため）
          isMaxSubscribersCountEnabled.value = plan.item.maxSubscribersCount !== undefined;
          isFirst.value = false;
        }
      }
    );

    const save = async () => {
      if (!(await confirmDialog('本当に保存しますか？'))) return;
      if (!isMaxSubscribersCountEnabled.value) {
        // 加入者数上限フラグをOFFにする場合は更新しないようにする
        plan.item.maxSubscribersCount = undefined;
        plan.item.isEnrollmentStopped = undefined;
      } else if (plan.item.maxSubscribersCount === undefined) {
        // 加入者数上限フラグがONなのに値が未設定の場合はエラー（フラグの情報はクラスで持っていないためここで判定）
        notification.error('加入者数上限値を設定してください。');
        return;
      }
      plan.isSaving = true;
      try {
        if (planId) {
          await plan.savePlan(planId);
          notification.notify('変更しました。');
        } else {
          await plan.createPlan();
          notification.notify('作成しました。');
          context.root.$router.push('/plans');
        }
      } catch (error) {
        notification.error(error);
      }
      plan.isSaving = false;
    };

    return {
      pageTitle: 'プラン',
      myRoleSettings,
      isShippingAddress,
      isMaxSubscribersCountEnabled,
      plan,
      getOptionsTitle,
      minValue,
      getIntervalText,
      isUpdatable,
      isEditInterval,
      isEditPrice,
      isEditInitialPrice,
      priceErrorMessage,
      initialPriceErrorMessage,
      save,
    };
  },
});
